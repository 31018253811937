import html2canvas from 'html2canvas';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import constants from '../EddieCalculations/constants';
import {
  onBatteryDetails,
  onFinance,
  onMonthly,
  onSavings,
  onSystemDetails,
  onTotalGraph,
  onUtility,
  onWelcome
} from './onCloneHelper';
import {
  changeTab,
  generatePDF,
  setDefaultTab,
  uploadPDF,
  waitForGraph
} from './pdfHelper';
import { isPDFProcessing } from '../../actions';
import store from '../../store';

export const createPDF = async (
  quote,
  account,
  userData,
  productConfiguration,
  instantQuoteMode,
  currentPage,
  utilitySavingsType,
  skipUploadToS3
) => {
  store.dispatch(isPDFProcessing(true));
  const fileName = `Proposal_${quote.sfid}.pdf`;
  const { ENPHASE, TESLA } = constants;

  const svgElements = document.body.querySelectorAll('svg');
  svgElements.forEach((item) => {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
    item.style.width = null;
    item.style.height = null;
  });

  const commonConfig = {
    allowTaint: false,
    logging: true,
    useCORS: true
  };

  const sleep = () => {
    return new Promise((resolve) => setTimeout(resolve, 500));
  };

  const notSimpleState = document.querySelector('#savings-tab-1');
  const showTotalSavings = instantQuoteMode || !quote.graphSelections ?
  true : get(quote, 'graphSelections.totalSavings');
  const showSavingsOverTime = instantQuoteMode || !quote.graphSelections ?
  true : get(quote, 'graphSelections.savingsOverTime');
  const batteryManufacturer = quote.batteryManufacturer && quote.batteryManufacturer.toLowerCase();
  const is3pSelected = !!(batteryManufacturer === ENPHASE || batteryManufacturer === TESLA);

  const welcomeNode = document.querySelector('#welcome');
  const welcome = await html2canvas(welcomeNode, {
    ...commonConfig,
    onclone: (document) =>
      onWelcome(document, account, userData, productConfiguration, quote, instantQuoteMode)
  });
  const canvas = {
    welcome
  };

  const systemDetailsNode = document.querySelector('#systemDetails');
  const systemDetails = await html2canvas(systemDetailsNode, {
    ...commonConfig,
    onclone: (clone) => onSystemDetails(clone, quote.systemDesignBase64Img)
  });
  if (systemDetails) canvas.systemDetails = systemDetails;

  if (is3pSelected) {
    const batteryDetails = document.querySelector('#storageDetails');
    if (batteryDetails) {
      const battery = await html2canvas(batteryDetails, {
        ...commonConfig,
        onclone: (clone) => onBatteryDetails(clone)
      });
      canvas.battery = battery;
    }
  }

  if (currentPage === 'proposal') await changeTab('#savings-tab-1');

  const financeNode = document.querySelector('#finance>div');
  await sleep();
  const finance = await html2canvas(financeNode, {
    ...commonConfig,
    onclone: (clone) => onFinance(clone, notSimpleState, is3pSelected, currentPage)
  });
  if (finance) canvas.finance = finance;

  if (is3pSelected) {
    const utilityNode = document.querySelector('#finance>:last-child>:last-child');
    if (utilityNode) {
      const utility = await html2canvas(utilityNode, {
        ...commonConfig,
        onclone: (clone) => onUtility(clone, notSimpleState)
      });
      canvas.utility = utility;
    }
  }
  let monthlyProductionNode;
  if (currentPage === 'proposal') {
    // TODO: fix the image
    await document.querySelector('[data-for="monthlyProductionChart"]').click();
    await sleep();
    monthlyProductionNode = document.querySelector('#monthlyProdcutionChart');
  } else {
    monthlyProductionNode = document.querySelector('#monthly-chart');
  }

  if (monthlyProductionNode) {
    const monthlyProductionGraph = await html2canvas(monthlyProductionNode, {
      ...commonConfig,
      onclone: (clone) => onMonthly(clone, currentPage)
    });
    canvas.monthlyProductionGraph = monthlyProductionGraph;
  }

  const monthlyGraphCloseBtn = monthlyProductionNode.querySelector('[aria-label="close"]');
  if (monthlyGraphCloseBtn) monthlyGraphCloseBtn.click();

  if (notSimpleState) {
    if (currentPage === 'proposal') {
      await changeTab('#savings-tab-2');
    } else {
      await changeTab('#savingsOverTimeGraph');
    }

    await sleep();
    const savingsOverTimeGraph = currentPage === 'proposal' ?
      document.querySelector('#savings-tab-2') :
      document.querySelector('#savingsOverTimeGraph');
    if (savingsOverTimeGraph) {
      const savingsGraphSection = currentPage === 'proposal' ?
        document.querySelector('#savings-tabpanel-2') :
        document.querySelector('.year-savings');
      if (savingsGraphSection) {
        await waitForGraph('#savings-over-time-chart-canvas');
        const savingsGraph = await html2canvas(savingsGraphSection, {
          ...commonConfig,
          onclone: (clone) => onSavings(clone, currentPage)
        });
        canvas.savingsGraph = savingsGraph;
      }
    }

    if (currentPage === 'proposal') {
      await changeTab('#savings-tab-0');
    } else {
      await changeTab('#totalSavingsGraph');
    }

    await sleep();
    const totalSavingsGraph = currentPage === 'proposal'
      ? document.querySelector('#savings-tab-0')
      : document.querySelector('#totalSavingsGraph');
    if (totalSavingsGraph) {
      const totalGraphSection = currentPage === 'proposal'
        ? document.querySelector('#savings-tabpanel-0')
        : document.querySelector('.year-savings');
      if (totalGraphSection) {
        await waitForGraph('#total-savings-chart-canvas');
        const totalGraph = await html2canvas(totalGraphSection, {
          ...commonConfig,
          onclone: (clone) => onTotalGraph(clone, currentPage)
        });
        canvas.totalGraph = totalGraph;
      }
    }
  }

  const PDF = generatePDF(
    notSimpleState,
    canvas,
    is3pSelected,
    showSavingsOverTime,
    showTotalSavings,
    account.state
  );
  const blob = PDF.output('blob');
  const file = new File([blob], fileName);
  await setDefaultTab(account.partner);
  if (skipUploadToS3) {
    saveAs(blob, fileName);
    store.dispatch(isPDFProcessing(false));
  } else {
    uploadPDF(file, quote);
  }
};
