import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';
import Modal from 'react-bootstrap-modal';
import { register } from '../../utils/redux';
import { accountType, dynamicProposalType, proposalDetailType, quoteType } from '../../types';
import { showTitleCheck, skipTitleCheckModal, titleCheckNotAvailable } from '../../utils/titleCheck';
import constants from '../../utils/EddieCalculations/constants';
import { acknowledgementEnabled } from '../../utils/backendFeatureFlags';


const {
  ACKNOWLEDGEMENT_ACTION
} = constants;

export function ActionButtonView({ quote, updateProposal, proposalDetails, toggleComplianceCheckModal,
  toggleTitleCheckModal, showComplianceCheckModal, dynamicProposal, updateProposalSentVia, account, fetchTitleCheck }) {
  const sendOptions = [ { value: 'email', label: 'Send By Email' }, { value: 'text', label: 'Send By Text' } ];
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState(null);
  const [ selectedOptionLabel, setSelectedOptionLabel ] = useState(null);
  const isProposalSent = get(proposalDetails, 'isSent', false) || get(quote, 'mysunpowerC', false);
  const isVerifiedCompliance = get(quote, 'isVerifiedCompliance', false);
  const titleCheckDetails = get(quote, 'titleCheckDetails', null);
  const isPdfProcessing = get(account, 'isPDFProcessing');
  const showNotification = false;

  const toggle = (value, label) => {
    setSelectedOption(value);
    setSelectedOptionLabel(label);
    updateProposalSentVia(value);
  };

  const sendToMySunpower = () => {
    updateProposal(quote.sfid, 'send', dynamicProposal.sentVia);
  };

  const toggleDropdown = () => {
    if ((quote.cloneVariant !== 'amendment')) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };
  const closeModal = () => {
    toggleComplianceCheckModal(false);
  };

  const NotificationModal = (status) => {
    return (
      <Modal
        className="proposal-status-modal"
        show={status}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        data-keyboard="false"
        data-backdrop="static"
      >
        <Modal.Body className="proposal-status-modal-body">
          <div className="status-text">
            The Sales Representative is required to provide the customer with the Illinois
            Shines standard Disclosure Form, which must be signed by the customer before they sign an installation
            contract.
          </div>
          <a className="close-btn" onClick={closeModal}>OK</a>
        </Modal.Body>
      </Modal>
    );
  };

  const toggleModal = () => {
    if (account.state === 'IL') {
      NotificationModal(!showNotification);
    }
    if (isVerifiedCompliance) {
      if (titleCheckNotAvailable(titleCheckDetails) || showTitleCheck(titleCheckDetails)) {
        if (titleCheckNotAvailable(titleCheckDetails)) {
          fetchTitleCheck(quote, account, dynamicProposal);
        } else if (skipTitleCheckModal(titleCheckDetails)) {
          sendToMySunpower();
        } else {
          toggleTitleCheckModal(true);
        }
      } else {
        sendToMySunpower();
      }
    } else {
      toggleComplianceCheckModal(!showComplianceCheckModal);
    }
  };

  const acknowledgeProposal = () => {
    updateProposal(quote.sfid, ACKNOWLEDGEMENT_ACTION);
  };

  const options = (<ul>
    { sendOptions.map((option, index) => {
      return (
        <li
          className={cx('cs-option-container', { 'cs-selected': option.value === selectedOption })}
          onClick={() => {
            toggle(option.value, option.label);
            toggleDropdown();
          }}
          key={index}
        >
          <span className="cs-option">
            <div className="cs-option-name">{option.label}</div>
          </span>
        </li>
      );
    })}
  </ul>);

  const actionButtonContainer = () => {
    return (
      <Fragment>
        {!isPdfProcessing && !quote.isInstantQuote && (
          <div className={cx('action-button-view-container')} >
            <div className="action-btn-container">
              <div
                id="send_options_dropdown"
                className="input-container input-container--icon icon-right dropdown-with-tags send-option-select"
              >
                <i className="icon icon-chevron-down" />
                <div className={`select-dropdown cs-skin-border ${isDropdownOpen ? 'cs-active' : ''}`}>
                  <span className="cs-placeholder" onClick={() => toggleDropdown()}>
                    <div className="cs-option-name">{selectedOptionLabel || 'Select Send options'}</div>
                  </span>
                  <div className="cs-options">
                    {options}
                  </div>
                </div>
              </div>
              <button
                onClick={() => toggleModal()}
                className="btn btn-primary send-btn"
                disabled={
                 // TODO. Add Acknowledgement validation when enabled
                 (!(selectedOption)
                   || get(quote, 'mysunpowerC', false)) || Boolean(quote.cloneVariant === 'amendment')
                 }
              >
                {isProposalSent ? 'Sent to mySunPower' : 'Send to mySunPower'}
              </button>
            </div>
          </div>
          )}
      </Fragment>
    );
  };

  const quoteLockedView = () => {
    return (
      <div className={cx('action-button-view-container locked')}>
        <div className="locked-quote-placeholder">
          <img src={'/build/images/lock.svg'} alt="Locked Quote" />
          Proposal is locked and sent
        </div>
      </div>
    );
  };

  const acknowledgementView = () => {
    const leaseDocReviewedC = get(quote, 'leaseDocReviewedC');
    const isAcknowledgedFromSfdc = leaseDocReviewedC ? leaseDocReviewedC.toLowerCase() === 'acknowledged' : false;
    return (
      <div className={cx('action-button-view-container', quote.selectedFinanceOption)}>
        {
          acknowledgementEnabled() && (
            <label className="acknowledgement-container">
              <b>Check here to acknowledge</b> your solar power design,
              hardware, and proposal. I understand panel placement will
              vary based on electrical and structural design factors.
              <input
                type="checkbox"
                id="acknowledgement-checkbox"
                disabled={get(proposalDetails, 'isAcknowledged', false) || isAcknowledgedFromSfdc}
                checked={get(proposalDetails, 'isAcknowledged', false) || isAcknowledgedFromSfdc}
                onChange={acknowledgeProposal}
              />
              <span className="checkmark" />
            </label>
          )
        }
      </div>
    );
  };

  return (
    <section className="action-btn-section">
      {
        acknowledgementView()
      }
      {
        isProposalSent ?
          quoteLockedView() :
          actionButtonContainer()
      }
    </section>
  );
}

ActionButtonView.propTypes = {
  quote: quoteType.isRequired,
  updateProposal: PropTypes.func.isRequired,
  proposalDetails: proposalDetailType,
  toggleComplianceCheckModal: PropTypes.func.isRequired,
  toggleTitleCheckModal: PropTypes.func.isRequired,
  showComplianceCheckModal: PropTypes.bool.isRequired,
  dynamicProposal: dynamicProposalType.isRequired,
  updateProposalSentVia: PropTypes.func.isRequired,
  fetchTitleCheck: PropTypes.func.isRequired,
  account: accountType.isRequired
};

export default register(
  [],
  [],
  ActionButtonView
);
