import { jsPDF } from 'jspdf';
import { uploadProposalFile } from '../../actions';
import store from '../../store';
import {
  partnerChannelIsDirect,
  partnerChannelIsIndirect,
  partnerIsTps
} from '../../utils/EddieCalculations/helpers/account';
import constants from '../EddieCalculations/constants';
import dynamicPdfText from '../dynamicPdfText';

const pageWidth = 210;
const pageHeight = 270;
const { CA } = constants;
const {
  CPUC_HEADER,
  CPUC_LABEL,
  CPUC_LINE_1,
  CPUC_LINE_2_HEADER,
  CPUC_LINE_2,
  CPUC_LINE_3_HEADER,
  CPUC_LINE_3,
  CPUC_LINE_4_HEADER,
  CPUC_LINE_4
} = dynamicPdfText;

const newPage = (page) => {
  return page % 1 !== 0.5;
};

const calculateSize = (canvas, full) => {
  const width = parseInt(canvas.style.width, 10);
  const height = parseInt(canvas.style.height, 10);
  const widthRatio = pageWidth / width;
  const heightRatio = (full ? pageHeight : pageHeight / 2) / height;
  const ratio = widthRatio < heightRatio ? widthRatio : heightRatio;
  return {
    width: width * ratio,
    height: height * ratio
  };
};

const convertElementToImage = (element, page = 1, full = false) => {
  const size = calculateSize(element, full);
  const horizontalMargin = size.width < pageWidth ? (pageWidth - size.width) / 2 : 0;
  const secondHalf = page % 1 === 0.5;
  const positionMargin = secondHalf ? pageHeight / 2 : 0;
  const fullHeight = full ? pageHeight : pageHeight / 2;
  const alignmentMargin = size.height < fullHeight ? (fullHeight - size.height) / 2 : 0;
  const verticalMargin = positionMargin + alignmentMargin;
  return [
    element,
    'JPEG',
    horizontalMargin,
    verticalMargin,
    size.width,
    size.height,
    undefined,
    'FAST'
  ];
};

export const changeTab = async (element) => {
  const tab = element && document.querySelector(element);
  if (tab) tab.click();
};

export const setDefaultTab = async (partner) => {
  const isTps = partnerIsTps(partner);
  const isDirect = partnerChannelIsDirect(partner);
  const isIndirect = partnerChannelIsIndirect(partner);
  const totalTab = document.querySelector('#totalSavingsGraph');
  const overTimeTab = document.querySelector('#savingsOverTimeGraph');
  const setTotal = isDirect && totalTab;
  const setOvertime = !setTotal && overTimeTab && isTps || isIndirect;
  if (setOvertime && overTimeTab) await changeTab('#savingsOverTimeGraph');
  if (!setOvertime && totalTab) await changeTab('#totalSavingsGraph');
};

export const waitForGraph = async (query) => {
  const isGraphReady = new Promise((resolve) => {
    if (document.querySelector(query)) {
      return resolve(document.querySelector(query));
    }
    const observer = new MutationObserver(() => {
      if (document.querySelector(query)) {
        observer.disconnect();
        resolve(document.querySelector(query));
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
    return null;
  });
  return await isGraphReady;
};

export const generatePDF = (
  notSimpleState,
  canvas,
  is3pSelected,
  showSavingsOverTime,
  showTotalSavings,
  state
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: [ pageWidth, pageHeight ]
  });
  pdf.setFont('Helvetica');
  pdf.setFontSize(10);
  pdf.setFillColor(1);
  // Welcome and system details pages
  const welcomeImage = convertElementToImage(canvas.welcome);
  pdf.addImage(...welcomeImage);
  if (canvas.systemDetails) {
    const systemDetailsImage = convertElementToImage(canvas.systemDetails, 0.5);
    pdf.addImage(...systemDetailsImage);
  }
  pdf.addPage();
  let currentPage = 1;
  // Battery page
  if (is3pSelected) {
    const batteryImage = convertElementToImage(canvas.battery);
    pdf.addImage(...batteryImage);
    currentPage += 0.5;
  }
  // Finance page
  if (canvas.finance) {
    const financeImage = convertElementToImage(canvas.finance, currentPage, !is3pSelected);
    pdf.addImage(...financeImage);
    currentPage += !is3pSelected ? 1 : 0.5;
    if (newPage(currentPage)) pdf.addPage();
  }
  // Utility node page
  if (canvas.utility) {
    const utilityImage = convertElementToImage(canvas.utility, currentPage);
    pdf.addImage(...utilityImage);
    currentPage += 0.5;
    if (newPage(currentPage)) pdf.addPage();
  }
  // Monthly production graph
  if (canvas.monthlyProductionGraph) {
    const monthlyGraphImage = convertElementToImage(
      canvas.monthlyProductionGraph, currentPage
    );
    pdf.addImage(...monthlyGraphImage);
    currentPage += 0.5;
    if (newPage(currentPage) && (showSavingsOverTime || showTotalSavings || state === CA)) {
      pdf.addPage();
    }
  }
  if (notSimpleState) {
    // Savings Over Time graph
    if (showSavingsOverTime && canvas.savingsGraph) {
      const savingsGraphImage = convertElementToImage(
        canvas.savingsGraph, currentPage
      );
      pdf.addImage(...savingsGraphImage);
      currentPage += 0.5;
      if (newPage(currentPage) && (showTotalSavings || state === CA)) {
        pdf.addPage();
      }
    }
    // Totals graph
    if (showTotalSavings && canvas.totalGraph) {
      const totalGraphImage = convertElementToImage(
        canvas.totalGraph, currentPage
      );
      pdf.addImage(...totalGraphImage);
      currentPage += 0.5;
      if (newPage(currentPage) && state === CA) pdf.addPage();
    }
    // California Disclaimer
    if (state === CA) {
      const pdfHeight = newPage(currentPage) ? 25 : pageHeight / 2;
      const pdfWidth = pageWidth / 2;
      const pdfSplitText = (line, height) => {
        const split = pdf.splitTextToSize(line, 150);
        const margin = pdfHeight + height;
        return pdf.text(split, pdfWidth, margin, 'center');
      };
      pdf.setFontSize(12);
      pdf.setFont(undefined, 'bold');
      pdf.text(CPUC_HEADER, pdfWidth, pdfHeight + 5, 'center');
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      pdf.setTextColor(128, 128, 128);
      pdf.text(CPUC_LABEL, pdfWidth, pdfHeight + 10, 'center');
      pdf.setTextColor(0, 0, 0);
      pdfSplitText(CPUC_LINE_1, 20);
      pdf.setFont(undefined, 'bold');
      pdfSplitText(CPUC_LINE_2_HEADER, 42);
      pdf.setFont(undefined, 'normal');
      pdfSplitText(CPUC_LINE_2, 48);
      pdf.setFont(undefined, 'bold');
      pdfSplitText(CPUC_LINE_3_HEADER, 66);
      pdf.setFont(undefined, 'normal');
      pdfSplitText(CPUC_LINE_3, 72);
      pdf.setFont(undefined, 'bold');
      pdfSplitText(CPUC_LINE_4_HEADER, 98);
      pdf.setFont(undefined, 'normal');
      pdfSplitText(CPUC_LINE_4, 104);
    }
  }
  return pdf;
};

export const uploadPDF = (file, quote) => {
  const formData = new FormData();
  formData.append('file', file);
  store.dispatch(uploadProposalFile(quote.sfid, formData));
};
